.secInformation {
  margin: 60px 0 0;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin: 165px 0 0;
  }

  .secHead {
    .hdg {
      &__main {
        @include media-breakpoint-up(md) {
          height: 56px;
        }
      }

      &__sub {
        @include media-breakpoint-up(md) {
          margin: 15px 0 0;
        }
      }
    }
  }

  &__body {
    margin: 25px 0 0;

    @include media-breakpoint-up(md) {
      margin: 35px 0 0;
    }

    .swiper-container {
      margin: 0 -8px;
      overflow: visible;

      @include media-breakpoint-up(md) {
        margin: 0 -12px;
      }
    }

    .swiper-slide {
      box-sizing: border-box;
      width: 324px;
      padding: 0 8px;

      @include media-breakpoint-up(md) {
        width: 484px;
        padding: 0 12px;
      }
    }

    .swiper-button-next {
      right: -10px;

      @include media-breakpoint-up(md) {
        right: -40px;
      }
    }

    .card {
      display: flex;
      height: 128px;
      overflow: hidden;
      background: #fff;
      border-radius: 10px;
      transition: background-color 0.4s $ease-out-sine;

      @include media-breakpoint-up(md) {
        height: 172px;
      }

      @include hover() {
        background: $color-baika-pink;

        .card__contents {
          .contentsDate,
          .contentsTtl {
            color: #fff;
          }
        }
      }

      &__img {
        position: relative;
        width: 160px;
        text-align: center;
        background: #ffe6f5;

        @include media-breakpoint-up(md) {
          width: 230px;
        }

        img {
          height: 100%;
        }

        .imgLabel {
          position: absolute;
          top: 5px;
          left: 0;

          &::before,
          &::after {
            position: absolute;
            right: -8px;
            width: 8px;
            height: 19px;
            content: '';
            background: #e52d8a;

            @include media-breakpoint-up(md) {
              width: 8px;
              height: 22px;
            }
          }

          &::before {
            top: 0;
            clip-path: polygon(0 0, 0% 100%, 100% 0);
          }

          &::after {
            bottom: 0;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
          }

          &__txt {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            padding: 0 10px;
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            background: linear-gradient(90deg, #e76398, #e52d8a);
          }
        }
      }

      &__contents {
        flex: 1;
        padding: 20px 10px;

        @include media-breakpoint-up(md) {
          padding: 25px 20px 25px;
        }

        .contentsDate,
        .contentsTtl {
          transition: color 0.4s $ease-out-sine;
        }

        .contentsDate {
          font-size: 13px;
          font-weight: 500;
          color: #828282;

          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }

        .contentsTtl {
          display: -webkit-box;
          min-height: 4.5em;
          margin: 5px 0 0;
          overflow: hidden;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-clamp: 3;

          @include media-breakpoint-up(md) {
            margin: 10px 0 0;
            font-size: 18px;
          }
        }
      }
    }
  }

  + .secInformation {
    @include media-breakpoint-up(md) {
      margin: 70px 0 0;
    }
  }
}

// animation

.secInformation .secInformation__body .swiper-container {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}
