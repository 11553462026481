.secDiary {
  padding: 70px 0 40px;
  overflow: hidden;
  background-color: #fcf1f1;

  @include media-breakpoint-up(md) {
    padding: 75px 0 95px;
  }

  &__body {
    margin: 30px 0 0;
  }

  &__main {
    .swiper-container {
      margin: 0 -8px;
      overflow: visible;

      @include media-breakpoint-up(md) {
        margin: 0 -12px;
      }
    }

    .swiper-slide {
      box-sizing: border-box;
      width: 324px;
      padding: 0 8px;

      @include media-breakpoint-up(md) {
        width: 424px;
        padding: 0 12px;
      }
    }

    .swiper-button-prev {
      left: -10px;

      @include media-breakpoint-up(md) {
        left: -40px;
      }
    }

    .swiper-button-next {
      right: -10px;

      @include media-breakpoint-up(md) {
        right: -40px;
      }
    }

    .btn {
      margin: 30px 0 0;
      text-align: center;
    }
  }

  .card {
    display: block;
    overflow: hidden;
    background: #fff;
    border-radius: 10px;
    transition: background-color 0.4s $ease-out-sine;

    @include media-breakpoint-up(md) {
      width: 400px;
    }

    @include hover() {
      background: $color-baika-pink;

      .card__body {
        .bodyDate,
        .bodyTxt {
          color: #fff;
        }
      }
    }

    &__img {
      position: relative;
      height: 188px;
      text-align: center;
      background: #ffe6f5;

      @include media-breakpoint-up(md) {
        height: 245px;
      }

      img {
        height: 100%;
      }

      .imgLabel {
        position: absolute;
        bottom: 5px;
        left: 0;

        &::before,
        &::after {
          position: absolute;
          right: -7px;
          width: 8px;
          height: 19px;
          content: '';
          background: #e52d8a;

          @include media-breakpoint-up(md) {
            width: 8px;
            height: 22px;
          }
        }

        &::before {
          top: 0;
          clip-path: polygon(0 0, 0% 100%, 100% 0);
        }

        &::after {
          bottom: 0;
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }

        &__txt {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          padding: 0 10px;
          font-size: 15px;
          font-weight: 500;
          color: #fff;
          background: linear-gradient(90deg, #e76398, #e52d8a);
        }
      }
    }

    &__body {
      padding: 15px 20px 20px;

      @include media-breakpoint-up(md) {
        padding: 20px 30px 25px;
      }

      .bodyDate {
        font-size: 14px;
        font-weight: 400;
        color: #828282;
        transition: color 0.4s $ease-out-sine;
      }

      .bodyTxt {
        display: -webkit-box;
        min-height: 2.8em;
        margin: 5px 0 0;
        overflow: hidden;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.4;
        text-overflow: ellipsis;
        transition: color 0.4s $ease-out-sine;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }
      }
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 40px 0 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px;
      margin: 55px 0 0;
    }

    .miniCard {
      position: relative;
      width: 340px;
      height: 114px;
      border-image-source: url('/assets/images/pages/common/compornent_border-frame.png');
      border-image-slice: 30;
      border-image-width: 15px;
      border-image-outset: 0;
      border-image-repeat: round;

      @include media-breakpoint-up(md) {
        width: 400px;
        height: 134px;
      }

      @include hover() {
        &::before {
          opacity: 1;
        }

        .miniCard__inner {
          &::before {
            background: $color-baika-pink;
          }
        }

        .miniCard__txt {
          color: #fff;
        }

        .miniCard__arw {
          &::before {
            background: #fff;
          }
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        border-image-source: url('/assets/images/pages/common/compornent_border-frame-baikaPink.png');
        border-image-slice: 30;
        border-image-width: 15px;
        border-image-outset: 0;
        border-image-repeat: round;
        opacity: 0;
        transition: opacity 0.4s $ease-out-sine;
      }

      &__inner {
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px 10px 10px;

        &::before {
          position: absolute;
          top: 10px;
          right: 10px;
          bottom: 10px;
          left: 10px;
          z-index: -1;
          content: '';
          background: #fff;
          transition: background-color 0.4s $ease-out-sine;
        }
      }

      &__img {
        width: 125px;

        @include media-breakpoint-up(md) {
          width: 150px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__txt {
        margin: 0 0 0 15px;
        font-family: $font-serif;
        font-size: 15px;
        font-weight: 500;
        transition: color 0.4s $ease-out-sine;

        @include media-breakpoint-up(md) {
          margin: 0 0 0 20px;
          font-size: 18px;
        }
      }

      &__arw {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        margin: 0 0 0 auto;

        @include media-breakpoint-up(md) {
          width: 18px;
          height: 18px;
        }

        &::before {
          $width: 7px;
          $aspect: calc(11 / 7);

          width: $width;
          height: $width * $aspect;
          content: '';
          background: $color-baika-pink;
          transition: background-color 0.4s $ease-out-sine;
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
          mask-repeat: no-repeat;
        }
      }
    }
  }
}

// animation

.secDiary .secDiary__main .swiper-container,
.secDiary .secDiary__sub .miniCard {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}
