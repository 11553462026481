.commonSecHead {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .hdg {
    display: flex;
    flex-direction: column;

    &__sub {
      font-family: $font-serif;
      font-size: 16px;
      font-weight: 500;

      @include media-breakpoint-up(md) {
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}

// animation

.commonSecHead {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}
