/* stylelint-disable */
/* stylelint-enable */
.bnrListContents {
  padding: 30px 0 25px;
  margin: 50px 0 0;
  overflow: visible;
  background: #fff;
}
@media (min-width: 768px) {
  .bnrListContents {
    padding: 45px 0;
    margin: 80px 0 0;
  }
}
.bnrListContents .swiper-container {
  margin: 0 -8px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .bnrListContents .swiper-container {
    margin: 0 -16px;
  }
}
.bnrListContents .swiper-slide {
  box-sizing: border-box;
  width: 238px;
  padding: 0 8px;
}
@media (min-width: 768px) {
  .bnrListContents .swiper-slide {
    width: 254px;
    padding: 0 16px;
  }
}
.bnrListContents .bnr {
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (hover: hover) and (pointer: fine) {
  .bnrListContents .bnr:hover {
    opacity: 0.6;
  }
}
.bnrListContents .bnr img {
  width: 100%;
  height: auto;
}

.commonSecHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commonSecHead .hdg {
  display: flex;
  flex-direction: column;
}
.commonSecHead .hdg__sub {
  font-family: "Zen Old Mincho", serif;
  font-size: 16px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .commonSecHead .hdg__sub {
    font-size: 20px;
    font-weight: 500;
  }
}

.commonSecHead {
  opacity: 0;
}
.commonSecHead.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secCharm__head {
  position: relative;
  padding: 165px 0 0;
}
@media (min-width: 768px) {
  .secCharm__head {
    padding: 110px 0;
  }
}
.secCharm__head .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-image: url("/assets/images/pages/index/charm_head-img.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 768px) {
  .secCharm__head .bg {
    height: 100%;
    background-image: url("/assets/images/pages/index/charm_head-img_md.jpg");
  }
}
.secCharm__head .card {
  position: relative;
  display: block;
  padding: 5px;
  border-image-source: url("/assets/images/pages/common/compornent_border-frame.png");
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;
}
@media (min-width: 768px) {
  .secCharm__head .card {
    width: 484px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secCharm__head .card:hover .card__head .c-circleIcon::after {
    opacity: 1;
    transform: scale(1.2);
  }
  .secCharm__head .card:hover .card__txt {
    color: #e52d8a;
  }
}
.secCharm__head .card__container {
  position: relative;
  z-index: 0;
}
.secCharm__head .card__container::before {
  position: absolute;
  inset: 9px;
  z-index: -1;
  content: "";
  background: #fff;
}
.secCharm__head .card__frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.secCharm__head .card__frame::before, .secCharm__head .card__frame::after {
  position: absolute;
  width: 31px;
  height: 31px;
  content: "";
  background: #e52d8a no-repeat center/contain;
  mask-image: url("/assets/images/pages/common/compornent_corner-frame.svg");
}
@media (min-width: 768px) {
  .secCharm__head .card__frame::before, .secCharm__head .card__frame::after {
    width: 44px;
    height: 44px;
  }
}
.secCharm__head .card__frame--top::before, .secCharm__head .card__frame--top::after {
  top: 6px;
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--top::before, .secCharm__head .card__frame--top::after {
    top: 8px;
  }
}
.secCharm__head .card__frame--top::before {
  left: 6px;
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--top::before {
    left: 8px;
  }
}
.secCharm__head .card__frame--top::after {
  right: 6px;
  transform: rotate(90deg);
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--top::after {
    right: 8px;
  }
}
.secCharm__head .card__frame--bottom::before, .secCharm__head .card__frame--bottom::after {
  bottom: 6px;
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--bottom::before, .secCharm__head .card__frame--bottom::after {
    bottom: 8px;
  }
}
.secCharm__head .card__frame--bottom::before {
  left: 6px;
  transform: rotate(270deg);
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--bottom::before {
    left: 8px;
  }
}
.secCharm__head .card__frame--bottom::after {
  right: 6px;
  transform: rotate(180deg);
}
@media (min-width: 768px) {
  .secCharm__head .card__frame--bottom::after {
    display: none;
  }
}
.secCharm__head .card__inner {
  padding: 35px 25px;
}
@media (min-width: 768px) {
  .secCharm__head .card__inner {
    padding: 50px 45px;
  }
}
.secCharm__head .card__head {
  display: flex;
  align-items: center;
}
.secCharm__head .card__head .headTtl {
  font-family: "Zen Old Mincho", serif;
  font-size: 21px;
  font-weight: 500;
  color: #e52d8a;
}
@media (min-width: 768px) {
  .secCharm__head .card__head .headTtl {
    font-size: 34px;
  }
}
.secCharm__head .card__head .c-circleIcon {
  position: relative;
  width: 36px;
  height: 36px;
  margin: 0 0 0 10px;
}
@media (min-width: 768px) {
  .secCharm__head .card__head .c-circleIcon {
    position: absolute;
    right: 17px;
    bottom: 17px;
    width: 52px;
    height: 52px;
    margin: 0;
  }
}
.secCharm__head .card__head .c-circleIcon::before {
  width: 6px;
  height: 10px;
}
@media (min-width: 768px) {
  .secCharm__head .card__head .c-circleIcon::before {
    width: 7px;
    height: 11px;
  }
}
.secCharm__head .card__head .c-circleIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border: 1px solid #e52d8a;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__head .card__txt {
  margin: 10px 0 0;
  font-family: "Zen Old Mincho", serif;
  font-size: 12px;
  line-height: 1.6;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secCharm__head .card__txt {
    margin: 20px 0 0;
    font-size: 14px;
    line-height: 1.9;
  }
}
.secCharm__body {
  padding: 40px 0 0;
}
@media (min-width: 768px) {
  .secCharm__body {
    padding: 60px 0 0;
  }
}
.secCharm__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .secCharm__inner {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }
}
.secCharm__inner .charm {
  position: relative;
}
@media (min-width: 768px) {
  .secCharm__inner .charm {
    width: 380px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secCharm__inner .charm:hover .charm__icon .c-circleIcon::after {
    opacity: 1;
    transform: scale(1.2);
  }
  .secCharm__inner .charm:hover .charm__img::after {
    opacity: 1;
  }
}
.secCharm__inner .charm__img {
  position: relative;
}
.secCharm__inner .charm__img::before, .secCharm__inner .charm__img::after {
  position: absolute;
  content: "";
}
.secCharm__inner .charm__img::before {
  bottom: 52px;
  left: -13px;
  width: 13px;
  height: 5px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: #ab125f;
}
@media (min-width: 768px) {
  .secCharm__inner .charm__img::before {
    bottom: 60px;
    left: -16px;
    width: 16px;
    height: 6px;
  }
}
.secCharm__inner .charm__img::after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* stylelint-disable */
  background: linear-gradient(0, rgba(231, 99, 152, 0.4), rgba(229, 45, 138, 0.4) 20%, transparent 75%);
  /* stylelint-enable */
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__inner .charm__img img {
  width: 100%;
  height: auto;
}
.secCharm__inner .charm__ttl {
  position: absolute;
  bottom: 14px;
  left: -13px;
}
@media (min-width: 768px) {
  .secCharm__inner .charm__ttl {
    bottom: 16px;
    left: -15px;
  }
}
.secCharm__inner .charm__ttl::before, .secCharm__inner .charm__ttl::after {
  position: absolute;
  right: -8px;
  width: 8px;
  height: 19px;
  content: "";
  background: #e52d8a;
}
@media (min-width: 768px) {
  .secCharm__inner .charm__ttl::before, .secCharm__inner .charm__ttl::after {
    width: 8px;
    height: 22px;
  }
}
.secCharm__inner .charm__ttl::before {
  top: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}
.secCharm__inner .charm__ttl::after {
  bottom: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}
.secCharm__inner .charm__ttl .ttl {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 15px;
  font-family: "Zen Old Mincho", serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #e76398, #e52d8a);
}
@media (min-width: 768px) {
  .secCharm__inner .charm__ttl .ttl {
    height: 44px;
    font-size: 20px;
  }
}
.secCharm__inner .charm__icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}
@media (min-width: 768px) {
  .secCharm__inner .charm__icon {
    right: 14px;
    bottom: 14px;
  }
}
.secCharm__inner .charm__icon .c-circleIcon::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border: 2px solid #e52d8a;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__sub {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0 0;
}
@media (min-width: 768px) {
  .secCharm__sub {
    flex-direction: row;
    gap: 50px;
    margin: 90px 0 0;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secCharm__sub .miniCard:hover .miniCard__container::before {
    opacity: 1;
  }
  .secCharm__sub .miniCard:hover .miniCard__inner::before {
    background: #e52d8a;
  }
  .secCharm__sub .miniCard:hover .miniCard__ttl {
    filter: brightness(1000%);
  }
  .secCharm__sub .miniCard:hover .miniCard__arw::before {
    background: #fff;
  }
}
.secCharm__sub .miniCard__container {
  position: relative;
  display: block;
  padding: 5px;
  border-image-source: url("/assets/images/pages/common/compornent_border-frame.png");
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__container {
    width: 380px;
  }
}
.secCharm__sub .miniCard__container::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-image-source: url("/assets/images/pages/common/compornent_border-frame-baikaPink.png");
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__sub .miniCard__inner {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  padding: 10px;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__inner {
    height: 100%;
    padding: 15px;
  }
}
.secCharm__sub .miniCard__inner::before {
  position: absolute;
  inset: 9px;
  z-index: -1;
  content: "";
  background: #fff;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__sub .miniCard__img img {
  width: 126px;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__img img {
    width: 140px;
  }
}
.secCharm__sub .miniCard__ttl {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 15px;
  transition: filter 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secCharm__sub .miniCard__ttl .txt {
  margin: 10px 0 0;
  font-family: "Zen Old Mincho", serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__ttl .txt {
    margin: 5px 0 0;
    font-size: 17px;
    letter-spacing: -0.05em;
  }
}
.secCharm__sub .miniCard__arw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__arw {
    width: 18px;
    height: 18px;
  }
}
.secCharm__sub .miniCard__arw::before {
  width: 7px;
  height: 11px;
  content: "";
  background: #e52d8a;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
  mask-repeat: no-repeat;
}
@media (min-width: 768px) {
  .secCharm__sub .miniCard__arw::before {
    width: 7px;
    height: 11px;
  }
}

.secCharm .secCharm__head .card {
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}
.secCharm .secCharm__head .card.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1), transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transform: translate3d(0, 0, 0);
}

.secCharm .secCharm__inner .charm,
.secCharm .secCharm_sub .miniCard {
  opacity: 0;
}
.secCharm .secCharm__inner .charm.is-active,
.secCharm .secCharm_sub .miniCard.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secDiary {
  padding: 70px 0 40px;
  overflow: hidden;
  background-color: #fcf1f1;
}
@media (min-width: 768px) {
  .secDiary {
    padding: 75px 0 95px;
  }
}
.secDiary__body {
  margin: 30px 0 0;
}
.secDiary__main .swiper-container {
  margin: 0 -8px;
  overflow: visible;
}
@media (min-width: 768px) {
  .secDiary__main .swiper-container {
    margin: 0 -12px;
  }
}
.secDiary__main .swiper-slide {
  box-sizing: border-box;
  width: 324px;
  padding: 0 8px;
}
@media (min-width: 768px) {
  .secDiary__main .swiper-slide {
    width: 424px;
    padding: 0 12px;
  }
}
.secDiary__main .swiper-button-prev {
  left: -10px;
}
@media (min-width: 768px) {
  .secDiary__main .swiper-button-prev {
    left: -40px;
  }
}
.secDiary__main .swiper-button-next {
  right: -10px;
}
@media (min-width: 768px) {
  .secDiary__main .swiper-button-next {
    right: -40px;
  }
}
.secDiary__main .btn {
  margin: 30px 0 0;
  text-align: center;
}
.secDiary .card {
  display: block;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secDiary .card {
    width: 400px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secDiary .card:hover {
    background: #e52d8a;
  }
  .secDiary .card:hover .card__body .bodyDate,
  .secDiary .card:hover .card__body .bodyTxt {
    color: #fff;
  }
}
.secDiary .card__img {
  position: relative;
  height: 188px;
  text-align: center;
  background: #ffe6f5;
}
@media (min-width: 768px) {
  .secDiary .card__img {
    height: 245px;
  }
}
.secDiary .card__img img {
  height: 100%;
}
.secDiary .card__img .imgLabel {
  position: absolute;
  bottom: 5px;
  left: 0;
}
.secDiary .card__img .imgLabel::before, .secDiary .card__img .imgLabel::after {
  position: absolute;
  right: -7px;
  width: 8px;
  height: 19px;
  content: "";
  background: #e52d8a;
}
@media (min-width: 768px) {
  .secDiary .card__img .imgLabel::before, .secDiary .card__img .imgLabel::after {
    width: 8px;
    height: 22px;
  }
}
.secDiary .card__img .imgLabel::before {
  top: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}
.secDiary .card__img .imgLabel::after {
  bottom: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}
.secDiary .card__img .imgLabel__txt {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #e76398, #e52d8a);
}
.secDiary .card__body {
  padding: 15px 20px 20px;
}
@media (min-width: 768px) {
  .secDiary .card__body {
    padding: 20px 30px 25px;
  }
}
.secDiary .card__body .bodyDate {
  font-size: 14px;
  font-weight: 400;
  color: #828282;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secDiary .card__body .bodyTxt {
  display: -webkit-box;
  min-height: 2.8em;
  margin: 5px 0 0;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  text-overflow: ellipsis;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
@media (min-width: 768px) {
  .secDiary .card__body .bodyTxt {
    font-size: 18px;
  }
}
.secDiary__sub {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 40px 0 0;
}
@media (min-width: 768px) {
  .secDiary__sub {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin: 55px 0 0;
  }
}
.secDiary__sub .miniCard {
  position: relative;
  width: 340px;
  height: 114px;
  border-image-source: url("/assets/images/pages/common/compornent_border-frame.png");
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;
}
@media (min-width: 768px) {
  .secDiary__sub .miniCard {
    width: 400px;
    height: 134px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secDiary__sub .miniCard:hover::before {
    opacity: 1;
  }
  .secDiary__sub .miniCard:hover .miniCard__inner::before {
    background: #e52d8a;
  }
  .secDiary__sub .miniCard:hover .miniCard__txt {
    color: #fff;
  }
  .secDiary__sub .miniCard:hover .miniCard__arw::before {
    background: #fff;
  }
}
.secDiary__sub .miniCard::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  border-image-source: url("/assets/images/pages/common/compornent_border-frame-baikaPink.png");
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secDiary__sub .miniCard__inner {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 10px 10px;
}
.secDiary__sub .miniCard__inner::before {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: -1;
  content: "";
  background: #fff;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secDiary__sub .miniCard__img {
  width: 125px;
}
@media (min-width: 768px) {
  .secDiary__sub .miniCard__img {
    width: 150px;
  }
}
.secDiary__sub .miniCard__img img {
  width: 100%;
  height: auto;
}
.secDiary__sub .miniCard__txt {
  margin: 0 0 0 15px;
  font-family: "Zen Old Mincho", serif;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secDiary__sub .miniCard__txt {
    margin: 0 0 0 20px;
    font-size: 18px;
  }
}
.secDiary__sub .miniCard__arw {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  margin: 0 0 0 auto;
}
@media (min-width: 768px) {
  .secDiary__sub .miniCard__arw {
    width: 18px;
    height: 18px;
  }
}
.secDiary__sub .miniCard__arw::before {
  width: 7px;
  height: 11px;
  content: "";
  background: #e52d8a;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
  mask-repeat: no-repeat;
}

.secDiary .secDiary__main .swiper-container,
.secDiary .secDiary__sub .miniCard {
  opacity: 0;
}
.secDiary .secDiary__main .swiper-container.is-active,
.secDiary .secDiary__sub .miniCard.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.secInformation {
  margin: 60px 0 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .secInformation {
    margin: 165px 0 0;
  }
}
@media (min-width: 768px) {
  .secInformation .secHead .hdg__main {
    height: 56px;
  }
}
@media (min-width: 768px) {
  .secInformation .secHead .hdg__sub {
    margin: 15px 0 0;
  }
}
.secInformation__body {
  margin: 25px 0 0;
}
@media (min-width: 768px) {
  .secInformation__body {
    margin: 35px 0 0;
  }
}
.secInformation__body .swiper-container {
  margin: 0 -8px;
  overflow: visible;
}
@media (min-width: 768px) {
  .secInformation__body .swiper-container {
    margin: 0 -12px;
  }
}
.secInformation__body .swiper-slide {
  box-sizing: border-box;
  width: 324px;
  padding: 0 8px;
}
@media (min-width: 768px) {
  .secInformation__body .swiper-slide {
    width: 484px;
    padding: 0 12px;
  }
}
.secInformation__body .swiper-button-next {
  right: -10px;
}
@media (min-width: 768px) {
  .secInformation__body .swiper-button-next {
    right: -40px;
  }
}
.secInformation__body .card {
  display: flex;
  height: 128px;
  overflow: hidden;
  background: #fff;
  border-radius: 10px;
  transition: background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
@media (min-width: 768px) {
  .secInformation__body .card {
    height: 172px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .secInformation__body .card:hover {
    background: #e52d8a;
  }
  .secInformation__body .card:hover .card__contents .contentsDate,
  .secInformation__body .card:hover .card__contents .contentsTtl {
    color: #fff;
  }
}
.secInformation__body .card__img {
  position: relative;
  width: 160px;
  text-align: center;
  background: #ffe6f5;
}
@media (min-width: 768px) {
  .secInformation__body .card__img {
    width: 230px;
  }
}
.secInformation__body .card__img img {
  height: 100%;
}
.secInformation__body .card__img .imgLabel {
  position: absolute;
  top: 5px;
  left: 0;
}
.secInformation__body .card__img .imgLabel::before, .secInformation__body .card__img .imgLabel::after {
  position: absolute;
  right: -8px;
  width: 8px;
  height: 19px;
  content: "";
  background: #e52d8a;
}
@media (min-width: 768px) {
  .secInformation__body .card__img .imgLabel::before, .secInformation__body .card__img .imgLabel::after {
    width: 8px;
    height: 22px;
  }
}
.secInformation__body .card__img .imgLabel::before {
  top: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
}
.secInformation__body .card__img .imgLabel::after {
  bottom: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}
.secInformation__body .card__img .imgLabel__txt {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #e76398, #e52d8a);
}
.secInformation__body .card__contents {
  flex: 1;
  padding: 20px 10px;
}
@media (min-width: 768px) {
  .secInformation__body .card__contents {
    padding: 25px 20px 25px;
  }
}
.secInformation__body .card__contents .contentsDate,
.secInformation__body .card__contents .contentsTtl {
  transition: color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.secInformation__body .card__contents .contentsDate {
  font-size: 13px;
  font-weight: 500;
  color: #828282;
}
@media (min-width: 768px) {
  .secInformation__body .card__contents .contentsDate {
    font-size: 14px;
  }
}
.secInformation__body .card__contents .contentsTtl {
  display: -webkit-box;
  min-height: 4.5em;
  margin: 5px 0 0;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
@media (min-width: 768px) {
  .secInformation__body .card__contents .contentsTtl {
    margin: 10px 0 0;
    font-size: 18px;
  }
}
@media (min-width: 768px) {
  .secInformation + .secInformation {
    margin: 70px 0 0;
  }
}

.secInformation .secInformation__body .swiper-container {
  opacity: 0;
}
.secInformation .secInformation__body .swiper-container.is-active {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}