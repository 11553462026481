.secCharm {
  &__head {
    position: relative;
    padding: 165px 0 0;

    @include media-breakpoint-up(md) {
      padding: 110px 0;
    }

    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-image: url('/assets/images/pages/index/charm_head-img.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include media-breakpoint-up(md) {
        height: 100%;
        background-image: url('/assets/images/pages/index/charm_head-img_md.jpg');
      }
    }

    .card {
      position: relative;
      display: block;
      padding: 5px;
      border-image-source: url('/assets/images/pages/common/compornent_border-frame.png');
      border-image-slice: 30;
      border-image-width: 15px;
      border-image-outset: 0;
      border-image-repeat: round;

      @include media-breakpoint-up(md) {
        width: 484px;
      }

      @include hover() {
        .card__head {
          .c-circleIcon {
            &::after {
              opacity: 1;
              transform: scale(1.2);
            }
          }
        }

        .card__txt {
          color: $color-baika-pink;
        }
      }

      &__container {
        position: relative;
        z-index: 0;

        &::before {
          position: absolute;
          inset: 9px;
          z-index: -1;
          content: '';
          background: #fff;
        }
      }

      &__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::before,
        &::after {
          position: absolute;
          width: 31px;
          height: 31px;
          content: '';
          background: $color-baika-pink no-repeat center/contain;
          mask-image: url('/assets/images/pages/common/compornent_corner-frame.svg');

          @include media-breakpoint-up(md) {
            width: 44px;
            height: 44px;
          }
        }

        &--top {
          &::before,
          &::after {
            top: 6px;

            @include media-breakpoint-up(md) {
              top: 8px;
            }
          }

          &::before {
            left: 6px;

            @include media-breakpoint-up(md) {
              left: 8px;
            }
          }

          &::after {
            right: 6px;
            transform: rotate(90deg);

            @include media-breakpoint-up(md) {
              right: 8px;
            }
          }
        }

        &--bottom {
          &::before,
          &::after {
            bottom: 6px;

            @include media-breakpoint-up(md) {
              bottom: 8px;
            }
          }

          &::before {
            left: 6px;
            transform: rotate(270deg);

            @include media-breakpoint-up(md) {
              left: 8px;
            }
          }

          &::after {
            right: 6px;
            transform: rotate(180deg);

            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
      }

      &__inner {
        padding: 35px 25px;

        @include media-breakpoint-up(md) {
          padding: 50px 45px;
        }
      }

      &__head {
        display: flex;
        align-items: center;

        .headTtl {
          font-family: $font-serif;
          font-size: 21px;
          font-weight: 500;
          color: $color-baika-pink;

          @include media-breakpoint-up(md) {
            font-size: 34px;
          }
        }

        .c-circleIcon {
          position: relative;
          width: 36px;
          height: 36px;
          margin: 0 0 0 10px;

          @include media-breakpoint-up(md) {
            position: absolute;
            right: 17px;
            bottom: 17px;
            width: 52px;
            height: 52px;
            margin: 0;
          }

          &::before {
            width: 6px;
            height: 10px;

            @include media-breakpoint-up(md) {
              width: 7px;
              height: 11px;
            }
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            border: 1px solid $color-baika-pink;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.4s $ease-out-sine,
              transform 0.4s $ease-out-sine;
          }
        }
      }

      &__txt {
        margin: 10px 0 0;
        font-family: $font-serif;
        font-size: 12px;
        line-height: 1.6;
        transition: color 0.4s $ease-out-sine;

        @include media-breakpoint-up(md) {
          margin: 20px 0 0;
          font-size: 14px;
          line-height: 1.9;
        }
      }
    }
  }

  &__body {
    padding: 40px 0 0;

    @include media-breakpoint-up(md) {
      padding: 60px 0 0;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
    }

    .charm {
      position: relative;

      @include media-breakpoint-up(md) {
        width: 380px;
      }

      @include hover() {
        .charm__icon {
          .c-circleIcon {
            &::after {
              opacity: 1;
              transform: scale(1.2);
            }
          }
        }

        .charm__img {
          &::after {
            opacity: 1;
          }
        }
      }

      &__img {
        position: relative;

        &::before,
        &::after {
          position: absolute;
          content: '';
        }

        &::before {
          bottom: 52px;
          left: -13px;
          width: 13px;
          height: 5px;
          clip-path: polygon(100% 0, 0% 100%, 100% 100%);
          background: #ab125f;

          @include media-breakpoint-up(md) {
            bottom: 60px;
            left: -16px;
            width: 16px;
            height: 6px;
          }
        }

        &::after {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          /* stylelint-disable */
          background: linear-gradient(
            0,
            rgba(#e76398, 0.4),
            rgba(#e52d8a, 0.4) 20%,
            transparent 75%
          );
          /* stylelint-enable */
          border-radius: 10px;
          opacity: 0;
          transition: opacity 0.4s $ease-out-sine;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      &__ttl {
        position: absolute;
        bottom: 14px;
        left: -13px;

        @include media-breakpoint-up(md) {
          bottom: 16px;
          left: -15px;
        }

        &::before,
        &::after {
          position: absolute;
          right: -8px;
          width: 8px;
          height: 19px;
          content: '';
          background: #e52d8a;

          @include media-breakpoint-up(md) {
            width: 8px;
            height: 22px;
          }
        }

        &::before {
          top: 0;
          clip-path: polygon(0 0, 0% 100%, 100% 0);
        }

        &::after {
          bottom: 0;
          clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }

        .ttl {
          position: relative;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 38px;
          padding: 0 15px;
          font-family: $font-serif;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          background: linear-gradient(90deg, #e76398, #e52d8a);

          @include media-breakpoint-up(md) {
            height: 44px;
            font-size: 20px;
          }
        }
      }

      &__icon {
        position: absolute;
        right: 12px;
        bottom: 12px;

        @include media-breakpoint-up(md) {
          right: 14px;
          bottom: 14px;
        }

        .c-circleIcon {
          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            border: 2px solid $color-baika-pink;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.4s $ease-out-sine,
              transform 0.4s $ease-out-sine;
          }
        }
      }
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px 0 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 50px;
      margin: 90px 0 0;
    }

    .miniCard {
      @include hover() {
        .miniCard__container {
          &::before {
            opacity: 1;
          }
        }

        .miniCard__inner {
          &::before {
            background: $color-baika-pink;
          }
        }

        .miniCard__ttl {
          filter: brightness(1000%);
        }

        .miniCard__arw {
          &::before {
            background: #fff;
          }
        }
      }

      &__container {
        position: relative;
        display: block;
        padding: 5px;
        border-image-source: url('/assets/images/pages/common/compornent_border-frame.png');
        border-image-slice: 30;
        border-image-width: 15px;
        border-image-outset: 0;
        border-image-repeat: round;

        @include media-breakpoint-up(md) {
          width: 380px;
        }

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: '';
          border-image-source: url('/assets/images/pages/common/compornent_border-frame-baikaPink.png');
          border-image-slice: 30;
          border-image-width: 15px;
          border-image-outset: 0;
          border-image-repeat: round;
          opacity: 0;
          transition: opacity 0.4s $ease-out-sine;
        }
      }

      &__inner {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        padding: 10px;

        @include media-breakpoint-up(md) {
          height: 100%;
          padding: 15px;
        }

        &::before {
          position: absolute;
          inset: 9px;
          z-index: -1;
          content: '';
          background: #fff;
          transition: background-color 0.4s $ease-out-sine;
        }
      }

      &__img {
        img {
          width: 126px;

          @include media-breakpoint-up(md) {
            width: 140px;
          }
        }
      }

      &__ttl {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 15px;
        transition: filter 0.4s $ease-out-sine;

        .txt {
          margin: 10px 0 0;
          font-family: $font-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;

          @include media-breakpoint-up(md) {
            margin: 5px 0 0;
            font-size: 17px;
            letter-spacing: -0.05em;
          }
        }
      }

      &__arw {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        margin: 0 0 0 auto;

        @include media-breakpoint-up(md) {
          width: 18px;
          height: 18px;
        }

        &::before {
          $width: 7px;
          $aspect: calc(11 / 7);

          width: $width;
          height: $width * $aspect;
          content: '';
          background: $color-baika-pink;
          transition: background-color 0.4s $ease-out-sine;
          mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
          mask-repeat: no-repeat;

          @include media-breakpoint-up(md) {
            width: 7px;
            height: 11px;
          }
        }
      }
    }
  }
}

// animation

.secCharm .secCharm__head .card {
  opacity: 0;
  transform: translate3d(0, 10px, 0);

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine, transform 0.4s $ease-out-sine;
    transform: translate3d(0, 0, 0);
  }
}

.secCharm .secCharm__inner .charm,
.secCharm .secCharm_sub .miniCard {
  opacity: 0;

  &.is-active {
    opacity: 1;
    transition: opacity 0.4s $ease-out-sine;
  }
}
