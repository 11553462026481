.bnrListContents {
  padding: 30px 0 25px;
  margin: 50px 0 0;
  overflow: visible;
  background: #fff;

  @include media-breakpoint-up(md) {
    padding: 45px 0;
    margin: 80px 0 0;
  }

  .swiper-container {
    margin: 0 -8px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin: 0 -16px;
    }
  }

  .swiper-slide {
    box-sizing: border-box;
    width: 238px;
    padding: 0 8px;

    @include media-breakpoint-up(md) {
      width: 254px;
      padding: 0 16px;
    }
  }

  .bnr {
    @include a-hover-fade();

    img {
      width: 100%;
      height: auto;
    }
  }
}
